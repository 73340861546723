import { PageProps } from 'gatsby'
import React from 'react'
import InvitePage from '../../components/pages/InvitePage'
import Layout from '../../layouts/layout'
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout'
import { CurrentUserState } from '../../state/CurrentUserState'

const Invite = (props: PageProps) => {
  if (typeof window === 'undefined') return null
  const { isAuthenticated } = CurrentUserState.useContainer()

  if (!isAuthenticated) {
    return (
      <UnauthenticatedLayout>
        <InvitePage ownershipId={props.params.ownershipId} />
      </UnauthenticatedLayout>
    )
  }
  return (
    <Layout>
      <InvitePage ownershipId={props.params.ownershipId} />
    </Layout>
  )
}

export default Invite
