import { useApolloClient } from '@apollo/client'
import { IAccountOwnership } from '../StudentPage/AccountManagersSection/types.d'
import FREE_EMAIL from './graphql/freeEmail.graphql'
import GET_OWNERSHIP from './graphql/getOwnership.graphql'
import UPDATE_OWNERSHIP from './graphql/updateOwnership.graphql'

const useData = () => {
  const client = useApolloClient()

  const fetchInvite = async (
    ownershipId: string
  ): Promise<IAccountOwnership | null> => {
    const { data } = await client.query({
      query: GET_OWNERSHIP,
      fetchPolicy: 'network-only',
      variables: {
        _id: ownershipId
      }
    })
    if (
      data &&
      data.accountOwnership
    ) {
      return data.accountOwnership
    }
    return null
  }

  const updateInvite = async (
    ownershipId: string,
    status: 'ACCEPTED' | 'DECLINED'
  ): Promise<boolean> => {
    const { data } = await client.mutate({
      mutation: UPDATE_OWNERSHIP,
      variables: {
        ownershipId,
        status,
      },
    })
    return data.updateAccountOwnership
  }

  const freeEmail = async (email) => {
    const { data } = await client.query({
      query: FREE_EMAIL,
      variables: {
        email,
      },
    })
    return data.freeEmail
  }

  return {
    fetchInvite,
    updateInvite,
    freeEmail,
  }
}

export default useData
