import { navigate, useLocation } from '@reach/router'
import useAmplitude from 'hooks/useAmplitude'
import { useEffect, useState } from 'react'
import { CurrentUserState } from '../../../state/CurrentUserState'
import { IAccountOwnership } from '../StudentPage/AccountManagersSection/types.d'
import useData from './useData'

const useInvitePageState = (ownershipId: string) => {
  const { isAuthenticated, currentUser } = CurrentUserState.useContainer()
  const location = useLocation()
  const { fetchInvite, updateInvite, freeEmail } = useData()
  const [invite, setInvite] = useState<IAccountOwnership | null>()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [shouldLogOut, setShouldLogOut] = useState(false)
  const { logEvent } = useAmplitude()
  useEffect(() => {
    const init = async () => {
      setLoading(true)

      try {
        const invite = await fetchInvite(ownershipId)
        if (invite && invite.status === 'ACCEPTED') {
          setError('This invite has already been accepted!')
        }
        if (invite && currentUser && (
          (invite.user && currentUser._id !== invite.user._id ) ||
          (invite.email && currentUser.email !== invite.email)
        )) {
          setError("You're logged in to the wrong account, please log out and try the link again.")
          setShouldLogOut(true)
        }
        if (invite && invite.status !== 'ACCEPTED' && (!currentUser ||
          (currentUser.email === invite.email || (invite.user && currentUser.email === invite.user.email)))) {
            setInvite(invite)
            return
          }
        setInvite(null)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    if (isAuthenticated !== null) {
      logEvent('Dashboard: Invite page visited')
      init()
    }
  }, [isAuthenticated])

  const accept = async () => {
    if (!invite) return
    if (isAuthenticated === false) {
      setLoading(true)
      const urlParams = new URLSearchParams(location.search)
      let isFree = false
      if (invite.email) {
        isFree = await freeEmail(invite.email)
      }
      urlParams.set('redirect', location.pathname)
      urlParams.set('autoAccept', 'true')
      if (!isFree) {
        navigate(
          `/login?${urlParams.toString()}`
        )
      } else {
        navigate(
          `/register?${urlParams.toString()}`
        )
      }
      setLoading(false)
    } else {
      await updateInvite(ownershipId, 'ACCEPTED')
      logEvent('Dashboard: Invitation accepted')
      navigate('/')
    }
  }

  const decline = async () => {
    if (!isAuthenticated) return false
    await updateInvite(ownershipId, 'DECLINED')
    logEvent('Dashboard: Invitation declined')
    navigate('/')
    return null
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    if (!invite) return
    if (urlParams.get('autoAccept') === 'true') {
      accept()
    }
    
  }, [invite])

  return {
    invite,
    error,
    shouldLogOut,
    loading,
    accept,
    decline,
  }
}

export default useInvitePageState
