import { navigate } from 'gatsby'
import { Avatar, Button } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import Loader from '../../UI/Loader'
import useInvitePageState from './useInvitePageState'

interface IProps {
  ownershipId: string
}

const Wrapper = styled.div`
  background-color: #1a0425;
  height: 100vh;
  padding-top: 100px;
`

const BigMessage = styled.div`
  color: #fff;
  font-family: 'Rammetto One';
  font-size: 30px;
  padding: 0 30px;
  line-height: 35px;
  max-width: 630px;
  margin: 0 auto;
  text-align: center;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`

const Title = styled.div`
  color: #fff;
  font-family: 'Rammetto One';
  margin-bottom: 20px;
  text-shadow: 0 3px 0 #afafaf, 0 6px 0 rgba(0, 0, 0, 0.4);
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > * {
    margin-right: 10px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Box = styled.div`
  background-color: #341644;
  max-width: 500px;
  margin: 0 auto;
  color: #fff;
  padding: 25px 15px;
  border-radius: 20px;
  > * {
    margin-bottom: 20px;
  }
`

const Child = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    margin-bottom: 20px;
  }
  > :nth-child(2) {
    font-family: 'Rammetto One';
  }
`

const InvitePage = (props: IProps) => {
  const { invite, error, loading, accept, decline, shouldLogOut } = useInvitePageState(
    props.ownershipId
  )

  if (loading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    )

  // if (isAuthenticated === false) {
  //   return (
  //     <Wrapper>
  //       <Content>
  //         <Title>Do you already have an account?</Title>
  //         <Actions>
  //           <Link to={`/login?redirect=${location.pathname}${location.search}`}>
  //             <Button size="regular" theme="confirm">
  //               Yes
  //             </Button>
  //           </Link>
  //           <Link
  //             to={`/register?redirect=${location.pathname}${location.search}`}
  //           >
  //             <Button size="regular" backgroundColor="#EC1A18">
  //               No
  //             </Button>
  //           </Link>
  //         </Actions>
  //       </Content>
  //     </Wrapper>
  //   )
  // }

  if (!invite)
    return (
      <Wrapper>
        <BigMessage>
          { error || "This invitation does not exist or you're not allowed to see it." }
          { shouldLogOut && <Actions>
            <Button size='regular' theme='primary' onClick={() => navigate('/logout')}>Logout</Button>
          </Actions> }
        </BigMessage>
      </Wrapper>
    )

  return (
    <Wrapper>
      <Content>
        <Title>Follow Child</Title>
        <Box>
          <Child>
            <Avatar user={invite && invite.zookeeper} size="130px" />
            <div>{invite.zookeeper.username}</div>
            <div>{invite.zookeeper.nickname}</div>
          </Child>
          <p>
            You have been invited to follow the child above on Night Zookeeper.
            Once you set up a free account, you will be able to see the child's
            work, monitor their progress and view settings.
          </p>
          <Actions>
            <Button size="small" theme="confirm" onClick={accept}>
              Accept
            </Button>
            <Button size="small" backgroundColor="#EC1A18" onClick={decline}>
              Decline
            </Button>
          </Actions>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default InvitePage
